import React from 'react'
import { Image } from '../components/Image'
import { Text } from '../components/Text'
import { Block } from '../components/Block'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'

export interface Service {
  nombre: string
  imagenSmall: {
    asset: {
      url: string
    }
  }
  descripcionListado: string
  slug: {
    current: string
  }
  category: {
    slug: {
      current: string
    }
  }
}

export const ServiceCard: React.FC<{ service: Service }> = ({ service }) => (
  <Block marginBottom="size3" tablet={{ marginBottom: 'none' }}>
    <Image
      ratio="16by9"
      src={imageUrlFor(buildImageObj(service.imagenSmall))
        .width(400)
        .height(Math.floor((9 / 16) * 400))
        .auto('format')
        .url()}
      marginBottom="size5"
    />
    <Text as="h2" center marginBottom="size3" uppercase>
      {service.nombre}
    </Text>
    <Text weight="light" center>
      {service.descripcionListado}
    </Text>
  </Block>
)
