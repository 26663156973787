import React from 'react'
import { Layout } from '../containers/Layout'
import { Row } from '../components/grid/Row'
import { Col } from '../components/grid/Col'
import { Container } from '../components/grid/Container'
import { ServiceCard, Service } from '../partials/ServiceCard'
import { MobileContent } from '../components/grid/MobileContent'
import { TarifaSection } from '../partials/index/TarifaSection'
import { Hero } from '../partials/Hero'
import { Mapa } from '../partials/index/Mapa'
import { Block } from '../components/Block'
import { OtrasTecnicasSection } from '../partials/OtrasTecnicasSection'
import { graphql } from 'gatsby'
import { GraphqlErrorList } from '../components/GraphqlErrorList'
import { mapEdgesToNodes, buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import { Link } from '../components/Link'
import { Seo } from '../partials/Seo'

export const query = graphql`
  query FindTecnicasDeGrupo($id: String!) {
    allSanityTecnica(filter: { category: { id: { eq: $id } } }) {
      edges {
        node {
          nombre
          descripcionListado
          imagenSmall {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          category {
            slug {
              current
            }
          }
          slug {
            current
          }
        }
      }
    }
    sanityCategoriaTecnicas(id: { eq: $id }) {
      id
      imagenFondo {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
      titulo
      description
      anterior {
        fichaImagen {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        titulo
        slug {
          current
        }
      }
      siguiente {
        fichaImagen {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
        }
        titulo
        slug {
          current
        }
      }
    }
  }
`

const Page = (props: any) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphqlErrorList errors={errors} />
      </Layout>
    )
  }

  const nodes = mapEdgesToNodes(data.allSanityTecnica) as Service[]
  const page = data.sanityCategoriaTecnicas

  return (
    <Layout fullWidth>
      <Seo title={`${page.titulo} - Centro Feeling`} />
      <Hero
        backgroundUrl={imageUrlFor(buildImageObj(page.imagenFondo))
          .width(1440)
          .height(Math.floor(600))
          .auto('format')
          .url()}
        title={page.titulo}
        description={page.description}
      />

      <Container marginBottom="size7">
        <MobileContent>
          <Row>
            {nodes.map((service, index) => (
              <Col key={index} mobile={12} tablet={6} desktop={4}>
                <ServiceCard service={service} />
                <Block
                  marginTop="size4"
                  marginBottom="size5"
                  textAlign="center"
                >
                  <Link
                    rightArrow
                    to={`/${service.category.slug.current}/${service.slug.current}`}
                  >
                    Más información
                  </Link>
                </Block>
              </Col>
            ))}
          </Row>
        </MobileContent>
      </Container>
      <Block marginBottom="size7">
        <OtrasTecnicasSection
          izquierda={page.anterior}
          derecha={page.siguiente}
        />
      </Block>
      <TarifaSection />
      <Mapa />
    </Layout>
  )
}

export default Page
