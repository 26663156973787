import React from 'react'
import { Container } from '../components/grid/Container'
import { Col } from '../components/grid/Col'
import { Row } from '../components/grid/Row'

import { Image } from '../components/Image'
import { Link } from '../components/Link'
import { Block } from '../components/Block'
import { media } from '../theme/mixins/media'
import styled from 'styled-components/macro'
import { grid } from '../theme'
import { rem } from 'polished'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'

interface Tecnica {
  fichaImagen: {
    asset: Object
  }
  titulo: string
  slug: {
    current: string
  }
}

interface Props {
  izquierda: Tecnica
  derecha: Tecnica
}

export const OtrasTecnicasSection: React.FC<Props> = ({
  izquierda,
  derecha,
}) => (
  <Container paddingTop="size5" tablet={{ paddingTop: 'size7' }}>
    <Wrapper>
      <Row justify="center">
        <Col tablet={{ size: 6, justifySelf: 'start' }}>
          <TecnicaWrapper>
            <Image
              src={imageUrlFor(buildImageObj(izquierda.fichaImagen))
                .width(300)
                .height(Math.floor((3 / 4) * 300))
                .auto('format')
                .url()}
              ratio="4by3"
            />
            <Block flex justify="center" marginTop="size4" marginBottom="size7">
              <Link leftArrow to={`/${izquierda.slug.current}`}>
                Ver {izquierda.titulo}
              </Link>
            </Block>
          </TecnicaWrapper>
        </Col>
        <Col tablet={{ size: 6, justifySelf: 'end' }}>
          <TecnicaWrapper>
            <Image
              src={imageUrlFor(buildImageObj(derecha.fichaImagen))
                .width(300)
                .height(Math.floor((3 / 4) * 300))
                .auto('format')
                .url()}
              ratio="4by3"
            />
            <Block flex justify="center" marginTop="size4" marginBottom="size7">
              <Link rightArrow to={`/${derecha.slug.current}`}>
                Ver {derecha.titulo}
              </Link>
            </Block>
          </TecnicaWrapper>
        </Col>
      </Row>
    </Wrapper>
  </Container>
)

const Wrapper = styled.div`
  ${media.onlyMobile`
    padding: 0 ${rem(grid.default.gap)};
  `}
`

const TecnicaWrapper = styled.div`
  width: 17rem;
`
